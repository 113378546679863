import React, { useState, useEffect } from 'react';

const LoadingScreen = ({ onLoadComplete, imagesToPreload }) => {
    const [progress, setProgress] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const preloadImages = async () => {
            let loadedCount = 0;
            const totalImages = imagesToPreload.length;

            const promises = imagesToPreload.map(
                (src) =>
                    new Promise((resolve, reject) => {
                        const img = new Image();
                        img.src = src;
                        img.onload = () => {
                            loadedCount++;
                            setProgress(Math.floor((loadedCount / totalImages) * 100));
                            resolve(src);
                        };
                        img.onerror = () => {
                            loadedCount++;
                            setProgress(Math.floor((loadedCount / totalImages) * 100));
                            console.error(`Failed to load image: ${src}`);
                            resolve(src); // Resolvemos de todas formas para no bloquear el progreso
                        };
                    })
            );

            try {
                await Promise.all(promises);
                // Añade un pequeño retraso para que se vea la animación completa
                setTimeout(() => {
                    setIsLoading(false);
                    if (onLoadComplete) onLoadComplete();
                }, 1000);
            } catch (error) {
                console.error('Error preloading images:', error);
                // Incluso con errores, continuar después de un tiempo
                setTimeout(() => {
                    setIsLoading(false);
                    if (onLoadComplete) onLoadComplete();
                }, 1000);
            }
        };

        preloadImages();
    }, [imagesToPreload, onLoadComplete]);

    if (!isLoading) return null;

    return (
        <div className="fixed inset-0 bg-gradient-to-b from-orange-50 to-red-100 flex flex-col items-center justify-center z-[9999]">
            <div className="animate-pulse flex flex-col items-center">
                <img
                    src="/img/escudo-compress.webp"
                    alt="Escudo de la Falla Padre Méndez"
                    className="h-40 w-40 mb-8"
                />
                <div className="text-2xl font-bold text-orange-700 mb-4">Cargando...</div>

                {/* Barra de progreso */}
                <div className="w-64 bg-gray-200 rounded-full h-4 overflow-hidden">
                    <div
                        className="h-full bg-orange-500 rounded-full transition-all duration-300 ease-out"
                        style={{ width: `${progress}%` }}
                    ></div>
                </div>
                <div className="mt-2 text-orange-800">{progress}%</div>
            </div>
        </div>
    );
};

export default LoadingScreen;