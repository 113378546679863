import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import ReactDOM from 'react-dom/client'
import HomePage from './HomePage'
import CalendariPage from './CalendariPage'
import Fundacio from './Fundacio'
import PresidentsPage from './PresidentsPage'
import FalleresMajoresPage from './FalleresMajoresPage'
import MonumentsPage from './MonumentsPage'
import CasalPage from './CasalPage'
import ApuntatPage from './ApuntatPage'
import Patrocinadores from './Patrocinadores'
import Dashboard from './Dashboard';
import ProtectedRoute from './ProtectedRoute'
import VideoCarlota from './VideoCarlota.jsx'
import VideoMarisol from './VideoMarisol.jsx'
import VideoJavier from './VideoJavier.jsx'
import VideoPilar from './VideoPilar.jsx'
import AudioJose from './AudioJose.jsx'
import AudioMireia from './AudioMireia.jsx'
import AudioMonica from './AudioMonica.jsx'
import VideoAnunciantes from './VideoAnunciantes.jsx'
import './i18n.js'

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/calendari" element={<CalendariPage />} />
        <Route path="/historia" element={<Fundacio />} />
        <Route path="/presidents" element={<PresidentsPage />} />
        <Route path="/falleresMajors" element={<FalleresMajoresPage />} />
        <Route path="/falles" element={<MonumentsPage />} />
        <Route path="/casal" element={<CasalPage />} />
        <Route path="/apuntat" element={<ApuntatPage />} />
        <Route path="/patrocinadores" element={<Patrocinadores />} />
        <Route path="/videoCarlota" element={<VideoCarlota />} />
        <Route path="/videoMarisol" element={<VideoMarisol />} />
        <Route path="/videoJavier" element={<VideoJavier />} />
        <Route path="/videoPilar" element={<VideoPilar />} />
        <Route path="/audioJose" element={<AudioJose />} />
        <Route path="/audioMireia" element={<AudioMireia />} />
        <Route path="/audioMonica" element={<AudioMonica />} />
        <Route path="/videoAnunciantes" element={<VideoAnunciantes />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />      </Routes>
    </Router>
  )
}


export default App