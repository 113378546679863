import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow } from "swiper/modules";
import { useTranslation } from 'react-i18next';
import "swiper/css";
import "swiper/css/effect-coverflow";
import "./styles.css";


// Configuración de Google Drive API (reemplaza con tus datos)
const GOOGLE_API_KEY = 'AIzaSyAku7U39MZr8JaW9BIuXMybigaPknwt4lU';
const FOLDER_ID = '1lhaq-lgjn4eTO78NfuRTj1M89ozDFYLP';

export const Carousel2 = () => {
    const [slides, setSlides] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { t, i18n } = useTranslation();


    useEffect(() => {
        const fetchDriveImages = async () => {
            try {
                const response = await fetch(
                    `https://www.googleapis.com/drive/v3/files?q='${FOLDER_ID}'+in+parents&key=${GOOGLE_API_KEY}&fields=files(id,name,webContentLink)`
                );

                if (!response.ok) throw new Error('Error fetching data');

                const data = await response.json();
                const validFiles = data.files.filter(file =>
                    file.name.match(/\.(jpeg|jpg|png|gif)$/i)
                );

                if (validFiles.length === 0) throw new Error('No images found');

                const images = validFiles.map(file => ({
                    url: `https://drive.google.com/thumbnail?id=${file.id}&sz=w1000`,
                    id: file.id
                }));

                setSlides(images);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchDriveImages();
    }, []);

    if (loading) {
        return <div className="page carousel-2-page">Cargando imágenes desde Google Drive...</div>;
    }

    if (error) {
        return <div className="page carousel-2-page">Error: {error}</div>;
    }

    return (
        <section className="page carousel-2-page">
            <div className="title-container">
                <h2 className="carousel-title">{t('galeria_imagenes')}</h2>
                <p className="carousel-instruction text-center">
                    <i className="fas fa-arrow-left"></i> {t('desliza_para_ver_mas')} 
                </p>            </div>
            <Swiper
                grabCursor
                centeredSlides
                slidesPerView="auto"
                effect="coverflow"
                loop
                preloadimages="true"
                watchSlidesProgress
                breakpoints={{
                    320: {
                        coverflowEffect: {
                            rotate: 30,
                            stretch: 0,
                            depth: 50,
                            modifier: 1
                        }
                    },
                    768: {
                        coverflowEffect: {
                            rotate: 40,
                            depth: 100
                        }
                    },
                    1024: {
                        coverflowEffect: {
                            rotate: 50,
                            depth: 150
                        }
                    }
                }}
                coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                }}
                modules={[EffectCoverflow]}
            >
                {slides.map((slide) => (
                    <SwiperSlide key={slide.id}>
                        <img
                            src={slide.url}
                            alt={`Imagen ${slide.id}`}
                            className="slide-image"
                            loading="eager"
                        />
                    </SwiperSlide>
                ))}
                <div className="swiper-pagination"></div>
            </Swiper>

            <div className="add-photo-container" style={{ marginTop: '40px' }}>
                <a 
                    href="https://drive.google.com/drive/folders/1lhaq-lgjn4eTO78NfuRTj1M89ozDFYLP?usp=drive_link" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="add-photo-btn"
                    style={{
                        display: 'inline-block',
                        padding: '10px 20px',
                        backgroundColor: '#d84400',
                        color: 'white',
                        textDecoration: 'none',
                        borderRadius: '4px',
                        fontWeight: 'bold',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                        transition: 'background-color 0.3s ease'
                    }}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#ec672b'}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#d84400'}
                >
                    {t('añadir_fotos')}
                </a>
            </div>
        </section>
    );
};