import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const SponsorsSection = () => {
    const { t } = useTranslation();
    const [anunciantes, setAnunciantes] = useState([]);

    useEffect(() => {
        // Definir los nombres de los archivos de anunciantes
        const nombresAnunciantes = [
            "anunciante1.png",
            "anunciante2.png",
            "anunciante45.png",
            "anunciante3.png",
            "anunciante4.png",
            "anunciante5.png",
            "anunciante6.png",
            "anunciante7.png",
            "anunciante8.png",
            "anunciante9.png",
            "anunciante10.png",
            "anunciante11.png",
            "anunciante12.png",
            "anunciante13.png",
            "anunciante14.png",
            "anunciante15.png",
            "anunciante16.png",
            "anunciante17.png",
            "anunciante18.png",
            "anunciante19.png",
            "anunciante20.png",
            "anunciante21.png",
            "anunciante22.png",
            "anunciante23.png",
            "anunciante24.png",
            "anunciante25.png",
            "anunciante26.png",
            "anunciante27.png",
            "anunciante28.png",
            "anunciante29.png",
            "anunciante30.png",
            "anunciante31.png",
            "anunciante32.png",
            "anunciante33.png",
            "anunciante34.png",
            "anunciante35.png",
            "anunciante36.png",
            "anunciante37.png",
            "anunciante38.png",
            "anunciante39.png",
            "anunciante40.png",
            "anunciante41.png",
            "anunciante42.png",
            "anunciante43.png",
            "anunciante44.png",
            // Añade más nombres según sea necesario
        ];

        // Crear la lista de anunciantes con las rutas correctas
        const listaAnunciantes = nombresAnunciantes
            .filter(nombre => nombre) // Filtrar cualquier entrada undefined o vacía
            .map((nombre, index) => ({
                id: index,
                name: `Anunciante ${index + 1}`,
                logo: `/img/anunciantes/${nombre}`
            }));

        setAnunciantes(listaAnunciantes);
    }, []);

    return (
        <section className="py-16 bg-white">
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-bold text-center mb-12">{t('patrocinadoreslogo')}</h2>

                <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-8">
                    {anunciantes.map((anunciante) => (
                        <div
                            key={anunciante.id}
                            className="bg-gradient-to-r from-orange-100 to-red-100 rounded-lg shadow-md p-4 flex items-center justify-center"
                        >
                            <img
                                src={anunciante.logo}
                                alt={anunciante.name}
                                className="w-full h-auto object-contain min-h-24 max-h-96" // Imagen con altura mínima y máxima
                                style={{ minWidth: '80px' }} // Aseguramos un ancho mínimo para la imagen
                                onError={(e) => {
                                    console.log(`Error cargando imagen: ${anunciante.logo}`);
                                    e.target.src = "/placeholder.svg?height=100&width=200";
                                }}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default SponsorsSection;