// pages/AudioJose.jsx
import React from 'react';
import AudioPlayer from './components/AudioPlayer';

const AudioJose = () => {
    return (
        <div style={styles.container}>
            <img src="img/movil-compress.webp" alt="escudo" style={styles.image} />

            <div style={styles.audioContainer}>
                <AudioPlayer
                    audioSrc="/audio/josedeamo.mp4"
                    title="Palabras de Jose"
                    style={styles.audioPlayer} // Asegura que el reproductor se mantenga dentro
                />
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
        backgroundColor: '#eef2f3',
        padding: '20px',
    },
    title: {
        fontSize: '2.5rem',
        fontWeight: 'bold',
        color: '#333',
        marginBottom: '20px',
    },
    image: {
        width: '180px',
        height: '180px',
        borderRadius: '10px',
        marginBottom: '20px',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    },
    audioContainer: {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
        width: '100%', // Que se ajuste mejor a móviles
        maxWidth: '400px', // Limita en pantallas grandes
    },
    audioPlayer: {
        width: '100%', // Asegura que el reproductor no se desborde
    },
};


export default AudioJose;
