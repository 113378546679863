import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "./UserContext";
import { motion } from "framer-motion";
import { LogOut, Edit, Settings } from "lucide-react";

// Importando imágenes de las insignias
import BunyolOro from "./assets/or.png";
import BunyolPlata from "./assets/argent.png";
import BunyolCobre from "./assets/coure.png";
import BunyolOroLaurel from "./assets/orifulles.png";
import BunyolOroBrillantes from "./assets/brillants.png";

const rewardImages = {
    "Bunyol de Coure": BunyolCobre,
    "Bunyol d'Argent": BunyolPlata,
    "Bunyol d'Or": BunyolOro,
    "Bunyol d'Or ab Fulles de Llorer": BunyolOroLaurel,
    "Bunyol d'Or i Brillants ab Fulles de Llorer": BunyolOroBrillantes,
};

export default function Dashboard() {
    const { user, setUser } = useUser();
    const navigate = useNavigate();
    const [mensualidades, setMensualidades] = useState([]);
    const [insignias, setInsignias] = useState([]);
    const [proximaInsignia, setProximaInsignia] = useState(null);
    const [proximaFecha, setProximaFecha] = useState(null);


    const handleLogout = () => {
        localStorage.removeItem("user");
        sessionStorage.removeItem("user");
        setUser(null);
        navigate("/");
    };

    useEffect(() => {
        if (user && user.fechaInscripcion) {
            const fechaInscripcion = new Date(user.fechaInscripcion);
            const fechaActual = new Date();
            const añoActual = fechaActual.getFullYear();
            const diffYears = añoActual - fechaInscripcion.getFullYear();

            // Ajustar el cálculo para contar desde el 8 de marzo
            let añosCumplidos = diffYears;
            const proximo8Marzo = new Date(añoActual, 2, 8); // 8 de marzo del año actual

            if (fechaActual < proximo8Marzo) {
                añosCumplidos -= 1; // No ha llegado el 8 de marzo de este año, restamos 1 año
            }

            const earnedInsignias = [];

            if (añosCumplidos >= 2) {
                earnedInsignias.push("Bunyol de Coure");
            }
            if (añosCumplidos >= 7) {
                earnedInsignias.push("Bunyol d'Argent");
            }
            if (añosCumplidos >= 10) {
                earnedInsignias.push("Bunyol d'Or");
            }
            if (añosCumplidos >= 20) {
                earnedInsignias.push("Bunyol d'Or ab Fulles de Llorer");
            }
            if (añosCumplidos >= 30) {
                earnedInsignias.push("Bunyol d'Or i Brillants ab Fulles de Llorer");
            }

            setInsignias(earnedInsignias);

            // Calcular la próxima insignia
            let siguienteInsignia = null;
            let siguienteFecha = null;

            if (añosCumplidos < 2) {
                siguienteInsignia = "Bunyol de Coure";
                siguienteFecha = new Date(fechaInscripcion.getFullYear() + 2, 2, 8);
            } else if (añosCumplidos < 7) {
                siguienteInsignia = "Bunyol d'Argent";
                siguienteFecha = new Date(fechaInscripcion.getFullYear() + 7, 2, 8);
            } else if (añosCumplidos < 10) {
                siguienteInsignia = "Bunyol d'Or";
                siguienteFecha = new Date(fechaInscripcion.getFullYear() + 10, 2, 8);
            } else if (añosCumplidos < 20) {
                siguienteInsignia = "Bunyol d'Or ab Fulles de Llorer";
                siguienteFecha = new Date(fechaInscripcion.getFullYear() + 20, 2, 8);
            } else if (añosCumplidos < 30) {
                siguienteInsignia = "Bunyol d'Or i Brillants ab Fulles de Llorer";
                siguienteFecha = new Date(fechaInscripcion.getFullYear() + 30, 2, 8);
            }

            setProximaInsignia(siguienteInsignia);
            setProximaFecha(siguienteFecha ? siguienteFecha.toLocaleDateString("es-ES") : null);




            // Ejemplo de mensualidades (puedes actualizar esto según tu lógica real)
            const mensualidadesPagadas = [
                { mes: "Enero 2025", estado: "Pagado" },
                { mes: "Febrero 2025", estado: "Pendiente" },
                { mes: "Marzo 2025", estado: "Pendiente" },
            ];
            setMensualidades(mensualidadesPagadas);
        }
    }, [user]);

    if (!user) {
        return (
            <div className="min-h-screen flex items-center justify-center bg-gray-100">
                <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    className="bg-white rounded-lg shadow-xl p-8"
                >
                    <p className="text-red-600 font-semibold text-lg">No estás logueado</p>
                </motion.div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gradient-to-br from-orange-100 to-orange-200 p-8">
            <div className="max-w-4xl mx-auto">
                <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    className="bg-white rounded-2xl shadow-2xl overflow-hidden"
                >
                    {/* Header */}
                    <div className="bg-orange-500 text-white p-6">
                        <h1 className="text-3xl font-bold">Tu Panel</h1>
                        <p className="mt-2 opacity-80">
                            Bienvenido, {user.nombreCompleto}. Aquí puedes ver la información de tu cuenta.
                        </p>
                    </div>

                    {/* User Info */}
                    <div className="p-6">
                        <div className="flex items-center mb-6">
                            <div className="h-20 w-20 rounded-full bg-gradient-to-br from-orange-400 to-orange-600 flex items-center justify-center mr-4 shadow-lg">
                                <span className="text-2xl font-bold text-white">{user.nombreCompleto.charAt(0)}</span>
                            </div>
                            <div>
                                <h2 className="text-2xl font-semibold text-gray-800">{user.nombreCompleto}</h2>
                                <p className="text-gray-500">DNI: {user.dni}</p>
                            </div>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div className="bg-gray-50 p-4 rounded-lg">
                                <h3 className="text-lg font-semibold text-gray-700 mb-2">Fecha de inscripción</h3>
                                <p className="text-gray-600">{user.fechaInscripcion}</p>
                            </div>

                            {/* Insignias */}
                            <div className="bg-gray-50 p-4 rounded-lg">
                                <h3 className="text-lg font-semibold text-gray-700 mb-2">Insignias</h3>
                                <div className="flex flex-col">
                                    {insignias.map((insignia) => (
                                        <div key={insignia} className="flex items-center mb-2">
                                            {rewardImages[insignia] && (
                                                <img src={rewardImages[insignia]} alt={insignia} className="w-12 h-12 mr-3" />
                                            )}
                                            <p className="text-gray-600">{insignia}</p>
                                        </div>
                                    ))}
                                    {insignias.length === 0 && <p className="text-gray-600">No tienes insignias aún</p>}
                                </div>

                                {proximaInsignia && (
                                    <div className="mt-4">
                                        <h4 className="text-md font-semibold text-gray-700">Próxima Insignia</h4>
                                        <p className="text-gray-600">
                                            {`Te toca el ${proximaInsignia} el ${proximaFecha}`}
                                        </p>
                                    </div>
                                )}
                            </div>

                        </div>

                        {/* Mensualidades */}
                        <div className="mt-8">
                            <h3 className="text-xl font-semibold text-gray-700 mb-4">Mensualidades</h3>
                            <div className="bg-gray-50 rounded-lg overflow-hidden">
                                {mensualidades.map((item, index) => (
                                    <div
                                        key={index}
                                        className={`flex justify-between items-center p-4 ${index % 2 === 0 ? "bg-gray-100" : "bg-gray-50"
                                            }`}
                                    >
                                        <span className="text-gray-700">{item.mes}</span>
                                        <span
                                            className={`px-3 py-1 rounded-full text-sm ${item.estado === "Pagado"
                                                ? "bg-green-200 text-green-800"
                                                : "bg-yellow-200 text-yellow-800"
                                                }`}
                                        >
                                            {item.estado}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* Action Buttons */}
                    <div className="bg-gray-50 p-6">
                        <div className="flex flex-wrap justify-between items-center gap-4">
                            <motion.button
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                className="flex items-center justify-center bg-orange-500 text-white px-6 py-3 rounded-lg shadow-lg hover:bg-orange-600 transition-colors"
                                onClick={() => {
                                    /* Acción para editar perfil */
                                }}
                            >
                                <Edit className="w-5 h-5 mr-2" />
                                Editar Perfil
                            </motion.button>
                            <motion.button
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                onClick={handleLogout}
                                className="flex items-center justify-center bg-blue-500 text-white px-6 py-3 rounded-lg shadow-lg hover:bg-blue-600 transition-colors"
                            >
                                <LogOut className="w-5 h-5 mr-2" />
                                Cerrar Sesión
                            </motion.button>
                            {user.es_admin && (
                                <motion.button
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    onClick={() => navigate("/admin")}
                                    className="flex items-center justify-center bg-red-500 text-white px-6 py-3 rounded-lg shadow-lg hover:bg-red-600 transition-colors"
                                >
                                    <Settings className="w-5 h-5 mr-2" />
                                    Administrar
                                </motion.button>
                            )}
                        </div>
                    </div>
                </motion.div>
            </div>
        </div>
    );
}
