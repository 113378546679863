// ProtectedRoute.jsx
import React from 'react';
import { useUser } from './UserContext';
import { Navigate } from 'react-router-dom';

export default function ProtectedRoute({ children }) {
    const { user } = useUser();

    if (!user) {
        // Redirigir al HOME o /login
        return <Navigate to="/" replace />;
    }

    return children;
}
