import React, { useState, useEffect, useRef } from 'react';
import './FullscreenVideo.css';

const FullscreenVideoPage = () => {
    const [activeVideo, setActiveVideo] = useState('horizontal');
    const horizontalVideoRef = useRef(null);
    const verticalVideoRef = useRef(null);

    useEffect(() => {
        // Configurar los event listeners para detectar cuando termina cada video
        const horizontalVideo = horizontalVideoRef.current;
        const verticalVideo = verticalVideoRef.current;

        const switchToVertical = () => {
            setActiveVideo('vertical');
            if (verticalVideo) {
                verticalVideo.currentTime = 0;
                verticalVideo.play();
            }
        };

        const switchToHorizontal = () => {
            setActiveVideo('horizontal');
            if (horizontalVideo) {
                horizontalVideo.currentTime = 0;
                horizontalVideo.play();
            }
        };

        // Agregar event listeners
        if (horizontalVideo) {
            horizontalVideo.addEventListener('ended', switchToVertical);
        }

        if (verticalVideo) {
            verticalVideo.addEventListener('ended', switchToHorizontal);
        }

        // Iniciar el primer video
        if (activeVideo === 'horizontal' && horizontalVideo) {
            horizontalVideo.play();
        } else if (activeVideo === 'vertical' && verticalVideo) {
            verticalVideo.play();
        }

        // Limpiar event listeners cuando el componente se desmonte
        return () => {
            if (horizontalVideo) {
                horizontalVideo.removeEventListener('ended', switchToVertical);
            }

            if (verticalVideo) {
                verticalVideo.removeEventListener('ended', switchToHorizontal);
            }
        };
    }, [activeVideo]);

    return (
        <div className="fullscreen-container">
            <div
                className={`video-container horizontal ${activeVideo === 'horizontal' ? 'active' : 'hidden'}`}
            >
                <video
                    ref={horizontalVideoRef}
                    className="fullscreen-video"
                    playsInline
                    muted
                >
                    <source src="/video/videoHorizontal.mp4" type="video/mp4" />
                    Tu navegador no soporta videos HTML5.
                </video>
            </div>

            <div
                className={`video-container vertical ${activeVideo === 'vertical' ? 'active' : 'hidden'}`}
            >
                <video
                    ref={verticalVideoRef}
                    className="fullscreen-video"
                    playsInline
                    muted
                >
                    <source src="/video/videoVertical.mp4" type="video/mp4" />
                    Tu navegador no soporta videos HTML5.
                </video>
            </div>
        </div>
    );
};

export default FullscreenVideoPage;