import React, { useEffect, useRef, useState } from 'react';

const VideoMarisol = () => {
    const videoRef = useRef(null);
    const [isFullscreen, setIsFullscreen] = useState(false);

    const handleFullscreenToggle = () => {
        if (videoRef.current) {
            if (!isFullscreen && videoRef.current.requestFullscreen) {
                videoRef.current.requestFullscreen();
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                }
            }
            setIsFullscreen(!isFullscreen); // Alternar estado de pantalla completa
        }
    };

    return (
        <div style={styles.container}>
            <h1 style={styles.title}>Entrevista amb Marisol</h1>
            <p style={styles.description}>La nostra fallera major </p>

            <div style={styles.videoWrapper}>
                <iframe
                    ref={videoRef}
                    style={styles.video}
                    src="https://www.youtube.com/embed/PDUJebrYjps?autoplay=1&mute=0"
                    title="Entrevista amb Marisol"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>

            {/* Botón para activar/desactivar pantalla completa */}
            <button onClick={handleFullscreenToggle} style={styles.fullscreenButton}>
                {isFullscreen ? 'Salir de pantalla completa' : 'Ver en pantalla completa'}
            </button>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        maxWidth: '100%',
        margin: '0',
        textAlign: 'center',
        position: 'relative',
        minHeight: '100vh',
        overflow: 'hidden',
    },
    title: {
        fontSize: '2rem',
        margin: '10px 0',
    },
    description: {
        fontSize: '1rem',
        margin: '20px 0',
        color: '#555',
    },
    videoWrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
    video: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
    },
    fullscreenButton: {
        marginTop: '20px',
        padding: '10px 20px',
        fontSize: '1rem',
        backgroundColor: '#007BFF',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
};

export default VideoMarisol;
