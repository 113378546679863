import React, { useState, useEffect, useRef } from 'react';
import { Menu, X, MapPin, Phone, Mail, Facebook, Instagram, ChevronLeft, ChevronRight, User, ArrowUp } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Carousel2 } from './components/Carousel2';
import { useUser } from './UserContext'
import InstagramFeed from './components/InstagramFeed';
import Button from "./components/Button";
import LoginModal from './components/Loginmodal';
import Anunciantes from './components/Anunciantes';
import LoadingScreen from './components/LoadingScreen';
const TikTokIcon = ({ size = 24, className = "" }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={className}>
        <path d="M9 12a4 4 0 1 0 4 4V4a5 5 0 0 0 5 5"></path>
    </svg>
);



export default function HomePage() {
    const { t, i18n } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [countdown, setCountdown] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    const [fallasStarted, setFallasStarted] = useState(false); // Estado para saber si las Fallas han comenzado
    const [yearsSinceFoundation, setYearsSinceFoundation] = useState(0);
    const [showVideo, setShowVideo] = useState(false);
    const navigate = useNavigate();
    const [showScrollTop, setShowScrollTop] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const { user, setUser } = useUser();
    const [anunciantes, setAnunciantes] = useState([]);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [pageLoaded, setPageLoaded] = useState(false); // Nuevo estado para controlar la carga de la página

    const images = [
        '/img/portada.webp',
        '/img/portada1.webp',
        '/img/portada2.webp',
        '/img/portada3.webp',
    ];

    const imagesToPreload = [
        '/img/escudo-compress.webp',
        '/img/movil-compress.webp',
        ...images, // Imágenes del carrusel
        '/img/marisol-compress.webp',
        '/img/presidenta-compress.webp',
        '/img/Carlota.webp',
        '/img/Javier.webp',
        '/img/fallas/fmajor-compress.webp',
        '/img/fallas/finfantil-compress.webp',
        '/img/Torren-logotipo.svg',
        // Otras imágenes de secciones
    ];

    const [showSplash, setShowSplash] = useState(true);

    const monuments = [
        {
            title: t('monumento_mayor'),
            lema: "Connectat a pare méndez",
            artista: "Dani Barea",
            image: "/img/fallas/fmajor-compress.webp?height=400&width=300",
        },
        {
            title: t('monumento_infantil'),
            lema: "Veo, veo",
            artista: "Dani Barea",
            image: "/img/fallas/finfantil-compress.webp?height=400&width=300",
        },
    ];

    useEffect(() => {
        // Hide splash screen after 3 seconds
        const timer = setTimeout(() => {
            setShowSplash(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            setShowScrollTop(window.pageYOffset > 300);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 5000);
        return () => clearInterval(interval);
    }, [images.length]);

    const nextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const prevImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const handleLogoClick = (e) => {
        e.preventDefault();
        setShowVideo(true);
    };

    // Actualiza el contador y verifica si las Fallas han comenzado
    useEffect(() => {
        const targetDate = new Date('2026-02-21T20:00:00').getTime();

        const updateCountdown = () => {
            const now = new Date().getTime();
            const difference = targetDate - now;

            if (difference > 0) {
                setCountdown({
                    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                    hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                    minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
                    seconds: Math.floor((difference % (1000 * 60)) / 1000)
                });
                setFallasStarted(false);
            } else {
                setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
                setFallasStarted(true);
            }
        };

        const timer = setInterval(updateCountdown, 1000);
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const foundationYear = 1972;
        setYearsSinceFoundation(currentYear - foundationYear);
    }, []);

    const handleClick = (e, path) => {
        e.preventDefault();
        if (path === '/') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
            navigate(path);
        }
        setIsMenuOpen(false);
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const navItems = [
        { name: t('inicio'), path: "/" },
        { name: t('calendario'), path: "/calendari" },
        { name: t('historia'), path: "/historia" },
        { name: t('presidentes'), path: "/presidents" },
        { name: t('falleras_mayores'), path: "/falleresMajors" },
        { name: t('fallas'), path: "/falles" },
        { name: t('casal'), path: "/casal" },
        { name: t('apuntate'), path: "/apuntat" },

    ];


    return (

        <>
            {!pageLoaded ? (
                <LoadingScreen
                    onLoadComplete={() => setPageLoaded(true)}
                    imagesToPreload={imagesToPreload}
                />
            ) : (
                <div className="max-h-screen bg-gradient-to-b from-orange-50 to-red-100 text-gray-900">

                    <div className="max-h-screen bg-gradient-to-b from-orange-50 to-red-100 text-gray-900">
                        <header
                            className="bg-white shadow-lg sticky top-0 z-50"
                        >
                            <div className="container mx-auto px-4 py-4 flex justify-between items-center">
                                <Link
                                    to="/"
                                    className="flex items-center"
                                    onClick={(e) => handleClick(e, '/')}
                                >
                                    <img
                                        src="/img/escudo-compress.webp"
                                        alt="Escudo de la Falla Padre Méndez"
                                        className="h-20 w-20 cursor-pointer"
                                        onClick={handleLogoClick}
                                    />
                                </Link>

                                {/* Menú horizontal solo en pantallas grandes (xl: 1280px+) */}
                                <nav className="hidden xl:flex space-x-8">
                                    {navItems.map((item) => (
                                        <div
                                            key={item.name}
                                        >
                                            <Link
                                                to={item.path}
                                                className="text-gray-700 hover:text-orange-500 font-semibold transition-colors duration-300 border-b-2 border-transparent hover:border-orange-500"
                                                onClick={(e) => handleClick(e, item.path)}
                                            >
                                                {item.name}
                                            </Link>
                                        </div>
                                    ))}
                                </nav>

                                {/* Controles derecho solo en pantallas grandes (xl: 1280px+) */}
                                <div className="hidden xl:flex items-center space-x-4">
                                    <div className="flex space-x-2">
                                        <Button
                                            variant={i18n.language === 'es' ? "default" : "outline"}
                                            size="sm"
                                            onClick={() => changeLanguage('es')}
                                        >
                                            CAS
                                        </Button>
                                        <Button
                                            variant={i18n.language === 'ca' ? "default" : "outline"}
                                            size="sm"
                                            onClick={() => changeLanguage('ca')}
                                        >
                                            VAL
                                        </Button>
                                    </div>
                                    <a
                                        href="https://www.facebook.com/fallaparemendez?locale=es_ES"
                                        aria-label="Facebook"
                                        className="text-gray-600 hover:text-orange-500 transition-colors duration-300"
                                    >
                                        <Facebook size={24} />
                                    </a>
                                    <a
                                        href="https://www.instagram.com/fallaparemendez/"
                                        aria-label="Instagram"
                                        className="text-gray-600 hover:text-orange-500 transition-colors duration-300"
                                    >
                                        <Instagram size={24} />
                                    </a>
                                    <a
                                        href="https://www.tiktok.com/@fallaparemendez"
                                        aria-label="TikTok"
                                        className="text-gray-600 hover:text-orange-500 transition-colors duration-300"
                                    >
                                        <TikTokIcon size={24} />
                                    </a>
                                    <button
                                        aria-label="Login"
                                        className="text-gray-600 hover:text-orange-500 transition-colors duration-300"
                                        onClick={() => setShowLogin(true)}                            >
                                        <User size={24} />
                                    </button>

                                </div>

                                {/* Toggle visible hasta 1280px (xl:hidden) */}
                                <button
                                    className="xl:hidden p-2 rounded-md hover:bg-gray-100 transition-colors duration-300"
                                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                                    aria-label="Toggle menu"
                                >
                                    {isMenuOpen ? <X size={36} /> : <Menu size={36} />}
                                </button>
                            </div>

                            {/* Menú móvil visible hasta 1280px */}
                            {isMenuOpen && (
                                <div
                                    className="xl:hidden bg-white py-4 px-4 shadow-inner"
                                >
                                    <nav className="flex flex-col space-y-4">
                                        {navItems.map((item) => (
                                            <div
                                                key={item.name}
                                            >
                                                <Link
                                                    to={item.path}
                                                    className="text-gray-700 hover:text-orange-500 font-semibold transition-colors duration-300"
                                                    onClick={(e) => handleClick(e, item.path)}
                                                >
                                                    {item.name}
                                                </Link>
                                            </div>
                                        ))}
                                    </nav>
                                    <hr className="my-4 border-gray-200" />
                                    <div className="flex justify-center space-x-4 mt-4">
                                        <Button
                                            variant={i18n.language === 'es' ? "default" : "outline"}
                                            size="sm"
                                            onClick={() => changeLanguage('es')}
                                        >
                                            ES
                                        </Button>
                                        <Button
                                            variant={i18n.language === 'ca' ? "default" : "outline"}
                                            size="sm"
                                            onClick={() => changeLanguage('ca')}
                                        >
                                            CA
                                        </Button>
                                    </div>
                                    <div className="flex justify-center space-x-4 mt-4">
                                        <a
                                            href="https://www.facebook.com/fallaparemendez?locale=es_ES"
                                            aria-label="Facebook"
                                            className="text-gray-600 hover:text-orange-500 transition-colors duration-300"
                                        >
                                            <Facebook size={24} />
                                        </a>
                                        <a
                                            href="https://www.instagram.com/fallaparemendez/"
                                            aria-label="Instagram"
                                            className="text-gray-600 hover:text-orange-500 transition-colors duration-300"
                                        >
                                            <Instagram size={24} />
                                        </a>
                                        <a
                                            href="https://www.tiktok.com/@fallaparemendez"
                                            aria-label="TikTok"
                                            className="text-gray-600 hover:text-orange-500 transition-colors duration-300"
                                        >
                                            <TikTokIcon size={24} />

                                        </a >
                                        <button
                                            aria-label="Login"
                                            className="text-gray-600 hover:text-orange-500 transition-colors duration-300"
                                            onClick={() => setShowLogin(true)}                                    >
                                            <User size={24} />
                                        </button>
                                    </div>
                                </div>
                            )}
                        </header>

                        <main>
                            <section
                                className="relative h-[85vh] overflow-hidden"
                            >
                                <div className="absolute inset-0">
                                    {images.map((img, index) => (
                                        <div
                                            key={img}
                                            className={`absolute inset-0 transition-opacity duration-1000 ${index === currentImageIndex ? 'opacity-100' : 'opacity-0'
                                                }`}
                                        >
                                            <img
                                                src={img}
                                                alt={`Imagen ${index + 1}`}
                                                className="w-full h-full object-cover"
                                                loading={index === 0 ? "eager" : "lazy"} // Solo la primera es "eager"
                                            />
                                        </div>
                                    ))}

                                </div>

                                <div className="absolute inset-0 bg-black bg-opacity-20" />
                                <div className="relative container mx-auto px-4 h-full flex flex-col justify-center items-center text-center ">
                                    <img
                                        src="/img/movil-compress.webp"
                                        alt="Escudo de la Falla Padre Méndez"
                                        className="h-32 w-32 md:h-32 md:w-32 lg:h-44 lg:w-44 mb-8 rounded-lg shadow-lg"
                                        style={{ filter: 'drop-shadow(1px 1px 2px red) drop-shadow(0 0 1em orange) drop-shadow(0 0 0.2em orange)' }}
                                    />

                                    <div
                                        className="p-4 rounded-lg mb-0 max-w-2xl " // Cambiado el padding a p-4 para un fondo más estrecho
                                    >
                                        <h1 className="text-3xl md:text-3xl lg:text-4xl font-bold text-white  p-2 rounded-lg drop-shadow-2xl" style={{ textShadow: '1px 1px 2px red, 0 0 1em orange, 0 0 0.2em orange' }}>
                                            {t('nombreFalla')}
                                        </h1>
                                    </div>

                                    <div
                                        className=" rounded-lg mb-0 max-w-xl bg-transparent" // Cambiado el padding a p-2
                                    >
                                        <p className="text-lg md:text-lg lg:text-xl text-white drop-shadow-lg  p-2 rounded-lg m-4" style={{ textShadow: '1px 1px 2px red, 0 0 1em orange, 0 0 0.2em orange' }}> {/* Cambiado a text-black */}
                                            {t('fundada_en')}
                                        </p>
                                    </div>

                                    <button
                                        className="bg-white text-black text-xs md:text-sm lg:text-sm font-bold py-3 px-8 rounded-full hover:bg-orange-500 hover:text-white transition-colors duration-300 shadow-md"
                                        onClick={() => {
                                            const section = document.getElementById("bienvenidos")
                                            if (section) {
                                                section.scrollIntoView({ behavior: "smooth", block: "start" })
                                                const headerOffset = document.querySelector('header')?.offsetHeight || 0
                                                const elementPosition = section.getBoundingClientRect().top
                                                const offsetPosition = elementPosition + window.pageYOffset - headerOffset
                                                window.scrollTo({
                                                    top: offsetPosition,
                                                    behavior: "smooth"
                                                })
                                            }
                                        }}
                                    >
                                        {t('descubre_mas')}
                                    </button>
                                </div>
                            </section>


                            <section id="bienvenidos" className="py-16 bg-white">
                                <div className="container mx-auto px-4">
                                    <h2 className="text-3xl font-bold text-center mb-8">{t('bienvenidos')}</h2>
                                    <div
                                        className="max-w-3xl mx-auto rounded-lg shadow-lg shadow-orange-500 overflow-hidden bg-gradient-to-r from-orange-100 to-red-100"
                                        style={{ perspective: '1000px', transformStyle: 'preserve-3d' }}
                                    >
                                        <div className="p-8 flex flex-col items-center"> {/* Flexbox for centering */}
                                            <p className="text-lg leading-relaxed mb-4 text-center">
                                                {t('bienvenidos_p1')}
                                            </p>
                                            <p className="text-lg leading-relaxed text-center">
                                                {t('bienvenidos_p3')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section className="py-16 bg-white">
                                <div className="container mx-auto px-4">
                                    <h2 className="text-3xl font-bold text-center mb-12">{t('representantes')} <span className="text-primary">2025</span></h2>
                                    <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
                                        {[
                                            { cargo: t('fallera_mayor'), nombre: "Marisol Merchante Castillo", image: "/img/marisol-compress.webp?height=300&width=200" },
                                            { cargo: t('presidenta'), nombre: "Pilar Carratalá Pascual", image: "/img/presidenta-compress.webp?height=300&width=200" },
                                            { cargo: t('fallera_mayor_infantil'), nombre: "Carlota Sanz Merchante", image: "/img/Carlota.webp?height=300&width=200" },
                                            { cargo: t('presidente_infantil'), nombre: "Francisco Javier Galán Noguera", image: "/img/Javier.webp?height=300&width=200" }
                                        ].map((representante) => (
                                            <div
                                                key={representante.cargo}
                                                className="bg-white rounded-lg shadow-md overflow-visible flex flex-col relative"
                                            >
                                                <div className="flex-shrink-0 flex justify-center items-center overflow-visible relative" style={{ height: '300px' }}>
                                                    <img
                                                        src={representante.image}
                                                        alt={representante.nombre}
                                                        className="object-contain h-full transition-transform duration-300 ease-in-out transform hover:scale-125 hover:z-10 hover:drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]" // Añadido 'hover:shadow-lg' para sombra
                                                    />
                                                </div>
                                                <div className="p-4 flex flex-col justify-center h-32">
                                                    <h3 className="font-bold text-lg mb-2 text-primary">{representante.cargo}</h3>
                                                    <p className="text-gray-600">{representante.nombre}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </section>

                            {/* SECCIÓN DEL CONTADOR actualizada con fireworks contenidos */}
                            <section className="py-16 bg-gradient-to-r from-orange-100 to-red-100">
                                <div className="container mx-auto px-4 text-center">
                                    <h2 className="text-3xl font-bold mb-8">{t('falles')} <span className="text-primary">2026</span></h2>

                                    {fallasStarted ? (
                                        <div className="relative">
                                            <div className="bg-white rounded-lg shadow-md p-8 mx-auto max-w-md relative z-20">
                                                <div className="text-4xl font-bold text-primary animate-bounce">
                                                    {t('ja_estem_en_falles') || "Ja estem en Falles!"}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
                                            {Object.entries(countdown).map(([unit, value]) => (
                                                <div
                                                    key={unit}
                                                    className="bg-white rounded-lg shadow-md p-6"
                                                >
                                                    <div className="text-4xl font-bold text-primary mb-2">
                                                        {value}
                                                    </div>
                                                    <div className="text-gray-600 capitalize">{t(unit)}</div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </section>

                            <section className="py-16 ">
                                <div className="container mx-auto px-4">
                                    <h2 className="text-3xl font-bold text-center mb-12">{t('datos_historicos')}</h2>
                                    <div className="grid grid-cols-2 md:grid-cols-3 gap-8">
                                        {[
                                            { title: t('anos'), number: yearsSinceFoundation },
                                            { title: t('comision'), number: '+400' },
                                            { title: t('fallera_mayor_torrent'), number: 1 },
                                            { title: t('fallera_mayor_infantil_torrent'), number: 1 },
                                            { title: t('corte_honor'), number: 5 },
                                            { title: t('presidentes'), number: 15 }
                                        ].map((dato) => (
                                            <div
                                                key={dato.title}
                                                className="bg-gradient-to-r from-orange-100 to-red-100 rounded-lg shadow-md p-6 text-center"
                                            >
                                                <div className="text-4xl font-bold text-primary mb-2">
                                                    {dato.number}
                                                </div>
                                                <div className="text-gray-600">{dato.title}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </section>

                            <section className="py-16 bg-white">
                                <div className="container mx-auto px-4">
                                    <h2 className="text-3xl font-bold text-center mb-12">
                                        {t('monumentos')} <span className="text-primary">2025!</span>
                                    </h2>
                                    <div className="flex justify-center flex-wrap gap-8 mb-12">
                                        {monuments.map((monument) => (
                                            <div
                                                key={monument.title}
                                                className="bg-white rounded-lg shadow-md overflow-visible w-auto max-w-xs mx-auto"
                                            >
                                                <div className="relative p-4 bg-gradient-to-r from-orange-100 to-red-100">
                                                    <img
                                                        src={monument.image}
                                                        alt={monument.title}
                                                        className="w-full h-80 object-contain transition-transform duration-500 ease-in-out transform hover:scale-150 hover:z-10 hover:drop-shadow-[12px_21px_26px_rgba(230,97,0,0.4)]"
                                                    />
                                                </div>
                                                <div className="p-6 text-center mt-4 bg-gradient-to-r from-orange-100 to-red-100">
                                                    <h3 className="font-bold text-xl mb-2 text-primary">
                                                        {monument.title}
                                                    </h3>
                                                    <p className="text-gray-600 mb-2">
                                                        {t('lema')}: {monument.lema}
                                                    </p>
                                                    <p className="text-gray-600">
                                                        {t('artista')}: {monument.artista}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <div className="mt-12 text-center">
                                        <div
                                            className="inline-block"
                                        >
                                            <img
                                                src="/img/Torren-logotipo.svg?height=100&width=200"
                                                alt={t('ajuntamentLogo')}
                                                className="mx-auto h-20 object-contain"
                                            />
                                            <p className="mt-6 text-xl text-gray-600">{t('subvencion_text')}</p>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <Anunciantes />

                            <InstagramFeed />

                            <Carousel2 />

                            

                        </main>



                        <footer className="bg-gradient-to-r from-orange-600 to-red-600 text-white py-12">
                            <div className="container mx-auto px-4">
                                <div className="grid md:grid-cols-3 gap-8">
                                    <div>
                                        <img
                                            src="/img/escudo-compress.webp"
                                            alt="escudo de la Falla Padre Méndez"
                                            className="h-24 w-auto mb-4 rounded-full border-2 border-white"
                                        />
                                    </div>
                                    <div>
                                        <h3 className="text-lg font-semibold mb-4">{t('contacto')}</h3>
                                        <ul className="space-y-2">
                                            <li className="flex items-center">
                                                <MapPin size={18} className="mr-2" />
                                                <span>c/ Padre Méndez, 78, 46900 Torrent, Valencia</span>
                                            </li>
                                            <li className="flex items-center">
                                                <Phone size={18} className="mr-2" />
                                                <span>+34 </span>
                                            </li>
                                            <li className="flex items-center">
                                                <Mail size={18} className="mr-2" />
                                                <a href="mailto:secretariofallapadremendez@gmail.com" className="hover:text-orange-200 transition-colors duration-300">
                                                    secretariofallapadremendez@gmail.com
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div>
                                        <h3 className="text-lg font-semibold mb-4">{t('siguenos')}</h3>
                                        <div className="flex space-x-4">
                                            <a
                                                href="https://www.facebook.com/fallaparemendez?locale=es_ES"
                                                aria-label="Facebook"
                                                className="hover:text-orange-200 transition-colors duration-300"
                                            >
                                                <Facebook size={24} />
                                            </a>
                                            <a
                                                href="https://www.instagram.com/fallaparemendez/"
                                                aria-label="Instagram"
                                                className="hover:text-orange-200 transition-colors duration-300"
                                            >
                                                <Instagram size={24} />
                                            </a>
                                            <a
                                                href="https://www.tiktok.com/@fallaparemendez"
                                                aria-label="TikTok"
                                                className="hover:text-orange-200 transition-colors duration-300"
                                            >
                                                <TikTokIcon size={24} />
                                            </a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </footer>

                        {showScrollTop && (
                            <button
                                className="fixed bottom-8 right-8 bg-orange-500 text-white p-3 rounded-full shadow-lg z-50"
                                onClick={scrollToTop}
                            >
                                <ArrowUp size={24} />
                            </button>
                        )}
                        {showVideo && (
                            <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
                                <div className="relative w-full max-w-4xl">
                                    <button
                                        className="absolute top-4 right-4 text-white text-2xl"
                                        onClick={() => setShowVideo(false)}
                                    >
                                        &times;
                                    </button>
                                    <video
                                        src="/video/logo.mp4"
                                        autoPlay
                                        playsInline
                                        className="w-full"
                                        onClick={() => setShowVideo(false)}
                                        onEnded={() => setShowVideo(false)}
                                    >
                                        {t('video_not_supported')}
                                    </video>
                                </div>
                            </div>
                        )}
                        {showLogin && (
                            <LoginModal onClose={() => setShowLogin(false)} setUser={setUser} navigate={navigate} />
                        )}

                    </div>
                </div>
            )}
        </>
    );
}
