import { useEffect, useState, useRef } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import Button from "./Button"; // Sin llaves


function InstagramFeed() {
    const { t } = useTranslation();
    const [posts, setPosts] = useState([]);
    const [startIndex, setStartIndex] = useState(0);
    const feedRef = useRef(null);
    const headerHeight = 80;

    const ACCESS_TOKEN = 'IGAAHPXKI2oP1BZAFBtaVBXY2QwZATNnY1UtaldMOUg4WnZAHOVJpa29wUW9tdF94UGdXTnlyb2JZASVhubTlMTG9fbmxyaEVieURNZAWtYSnpCbFYwb1ZAhbTNMdzhvUmtWdDM1OC00Y0lQakRpVDktUWVDajc2RHlqRjZADYVJtd2tXMAZDZD';

    const USER_ID = '17841446139034626';


    const scrollToFeed = () => {

        if (feedRef.current) {
            const headerHeight = document.querySelector('header')?.offsetHeight || 0;
            const feedTop = feedRef.current.getBoundingClientRect().top + window.pageYOffset - headerHeight;
            window.scrollTo({
                top: feedTop,
                behavior: 'smooth'
            });
        }
    };


    useEffect(() => {
        fetchInstagramPosts(startIndex);
    }, [startIndex]);

    async function fetchInstagramPosts(index) {
        try {
            const response = await fetch(
                `https://graph.instagram.com/${USER_ID}/media?fields=id,caption,media_type,media_url,permalink,timestamp,children{media_url,media_type}&access_token=${ACCESS_TOKEN}`
            );
            if (!response.ok) {
                // Log the error response for more details
                const errorText = await response.text(); // Get the error message from the response
                console.error('Error fetching Instagram posts:', response.status, response.statusText, errorText);
                throw new Error(`Network response was not ok: ${response.status} ${response.statusText} ${errorText}`);
            }
            const data = await response.json();
            setPosts(data.data.slice(index, index + 3));
        } catch (error) {
            console.error('Error fetching Instagram posts:', error);
        }
    }

    const handleNext = () => {
        setStartIndex(prevIndex => prevIndex + 3);
        setTimeout(scrollToFeed, 100);
    };

    const handlePrev = () => {
        setStartIndex(prevIndex => Math.max(0, prevIndex - 3));
        setTimeout(scrollToFeed, 100);
    };


    return (
        <section
            ref={feedRef}
            className="py-16 bg-gradient-to-r from-orange-100 to-red-100"
        >
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-bold text-center mb-12">{t('ultimas_publicaciones')}</h2>
                <div className="flex flex-col items-center gap-4">
                    {posts.map((post) => (
                        <div
                            key={post.id}
                            className="bg-white rounded-lg shadow-md w-full max-w-md"
                        >
                            <div className="p-4">
                                <h3 className="font-bold text-lg">{post.caption.split('\n')[0]}</h3>
                            </div>
                            <div className="p-0">
                                {post.media_type === 'VIDEO' ? (
                                    <video src={post.media_url} controls className="w-full h-auto object-cover" />
                                ) : (
                                    <img src={post.media_url} alt={post.caption} className="w-full h-auto object-cover" />
                                )}
                            </div>
                            <div className="p-4 flex flex-col items-start">
                                <p className="text-sm text-gray-600 mb-2">{new Date(post.timestamp).toLocaleDateString()}</p>
                                <button
                                    className="text-blue-600 hover:text-blue-800"
                                    onClick={() => window.open(post.permalink, '_blank')}
                                >
                                    {t('leer_mas')}
                                </button>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="flex justify-center mt-8 space-x-4">
                    <Button
                        variant="default"
                        onClick={handlePrev}
                        disabled={startIndex === 0}
                    >
                        {t('anterior')}
                    </Button>
                    <Button
                        variant="default"
                        onClick={handleNext}
                    >
                        {t('siguiente')}

                    </Button>

                </div>
            </div>
        </section>
    );
}
export default InstagramFeed;
