import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; 
import { useUser } from '../UserContext'


import ojoAbierto from '../assets/ojo.png';
import ojoCerrado from '../assets/visible.png';

function LoginModal({ onClose }) {
    const [dni, setDni] = useState('');
    const [password, setPassword] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const { setUser } = useUser();     // <-- Importante
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMensaje('');

        try {
            const response = await fetch('/.netlify/functions/login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ dni, password }),
            });

            const data = await response.json();

            // extracto de tu handleSubmit en HomePage.jsx o en el LoginModal:
            if (data.success) {
                // Guardar en el contexto
                setUser(data.user);
                // Redirigir a /dashboard
                navigate('/dashboard');
            } else {
                setMensaje(data.message);
            }

        } catch (error) {
            console.error('Error al hacer login:', error);
            setMensaje('Error al intentar hacer login');
        }
    };

    return (
        <>
            {/* Fondo oscuro semitransparente */}
            <div
                className="fixed inset-0 z-40 bg-black bg-opacity-50"
                onClick={onClose}
            />
            {/* Contenedor del modal */}
            <div className="fixed inset-0 z-50 flex items-center justify-center">
                <div className="bg-white w-full max-w-md mx-auto p-6 rounded-lg shadow-md relative">
                    {/* Botón cerrar */}
                    <button
                        onClick={onClose}
                        className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 font-bold"
                    >
                        X
                    </button>
                    <h2 className="text-2xl font-bold mb-4 text-center">Iniciar Sesión</h2>

                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div>
                            <label htmlFor="dni" className="block text-gray-700">
                                DNI
                            </label>
                            <input
                                type="text"
                                id="dni"
                                name="dni"
                                value={dni}
                                onChange={(e) => setDni(e.target.value)}
                                className="w-full border border-gray-300 rounded px-3 py-2 mt-1"
                                required
                            />
                        </div>

                        <div className="relative">
                            <label htmlFor="password" className="block text-gray-700">
                                Contraseña
                            </label>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="w-full border border-gray-300 rounded px-3 py-2 mt-1 pr-10"
                                required
                            />
                            <button
                                type="button"
                                className="absolute right-2 top-9"
                                onClick={() => setShowPassword(!showPassword)}
                                aria-label="Toggle password visibility"
                            >
                                <img
                                    src={showPassword ? ojoCerrado : ojoAbierto}
                                    alt="Mostrar/Ocultar contraseña"
                                    className="w-6 h-6"
                                />
                            </button>
                        </div>

                        <button
                            type="submit"
                            className="w-full bg-orange-500 text-white py-2 rounded hover:bg-orange-600 transition-colors duration-300"
                        >
                            Iniciar Sesión
                        </button>
                    </form>

                    {mensaje && (
                        <div className="flex justify-center mt-4">
                            <p className="font-bold text-red-600">
                                {mensaje}
                            </p>
                        </div>
                    )}

                </div>
            </div>
        </>
    );
}

export default LoginModal;