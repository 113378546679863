import { motion } from "framer-motion";
import React from "react";

const Button = ({ variant, size, onClick, children }) => (
    <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className={`px-3 py-1 rounded-md transition-colors duration-300 ${variant === 'default' ? 'bg-orange-500 text-white' : 'bg-white text-orange-500 border border-orange-500'
            } ${size === 'sm' ? 'text-sm' : 'text-base'}`}
        onClick={onClick}
    >
        {children}
    </motion.button>
);

export default Button;
