import React from 'react';

const AudioPlayer = ({ audioSrc, title }) => {
    return (
        <div className="audio-container">
            <h2>{title}</h2>
            <audio autoPlay controls>
                {/* Fuente MP3 como formato más común */}
                <source src={audioSrc.replace('.mp4', '.mp3')} type="audio/mpeg" />
                
                {/* Fuente OGG para mayor compatibilidad */}
                <source src={audioSrc.replace('.mp4', '.ogg')} type="audio/ogg" />
                
                {/* Fuente para archivos MP4 en caso de que sea necesario */}
                <source src={audioSrc} type="audio/mp4" />

                {/* Mensaje de error si el navegador no soporta el elemento de audio */}
                Tu navegador no soporta el elemento de audio.
            </audio>
        </div>
    );
};

export default AudioPlayer;
